import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadSampleResultsRankedUnits } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { SampleResultsRankedUnit } from '../types';

export const loadSampleResultsRankedUnits = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: { units: SampleResultsRankedUnit[] };
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadSampleResultsRankedUnits();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ units: SampleResultsRankedUnit[] }>) =>
            observableOf({
                result: convertToCamel<{ units: SampleResultsRankedUnit[] }>(response),
                actionName,
                processResultFunction,
            }),
        ),
    );
};
